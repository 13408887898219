<template>
  <div
    class="login"
    :class="$store.state.navigation ? 'xs-pa' : 'pa-10'"
    :style="'width:' + carouselHeight + 'px'"
  >
    <div class="_title">用户注册</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="register.email"
        :rules="rules.emailRules"
        label="邮箱"
        required
        class="required"
      />
      <v-text-field
        v-model="register.passwd"
        required
        class="required"
        type="password"
        label="密码"
        :rules="rules.passwd"
      />
      <v-text-field
        v-model="register.password"
        required
        class="required"
        type="password"
        label="确认密码"
        :rules="rules.password"
      />
      <v-text-field
        v-model="register.realName"
        required
        class="required"
        label="姓名"
        :rules="rules.realName"
      />
      <v-container class="pa-0" fluid style="box-shadow: none">
        <p>性别</p>
        <v-radio-group v-model="register.gender" row>
          <v-radio label="男" value="1"></v-radio>
          <v-radio label="女" value="0"></v-radio>
        </v-radio-group>
      </v-container>
      <v-text-field
        v-model="register.organization"
        required
        class="required"
        label="单位"
        :rules="rules.organization"
      />
      <v-text-field
        v-model="register.position"
        required
        class="required"
        :rules="rules.position"
        label="职称/学位"
      />
      <v-text-field
        v-model="register.phone"
        required
        class="required"
        label="手机"
        :rules="rules.phone"
      />
<!--        座机电话为非必须-->
<!--        class="required"-->
<!--      :rules="rules.call"-->
      <v-text-field
        v-model="register.call"
        label="座机电话"
      />
      <v-text-field
        v-model="register.invoiceOrg"
        required
        class="required"
        label="研究方向"
        :rules="rules.invoiceOrg"
      />
<!--      <v-text-field-->
<!--        v-model="register.taxLabel"-->
<!--        required-->
<!--        class="required"-->
<!--        label="纳税人识别号"-->
<!--        :rules="rules.taxLabel"-->
<!--      />-->
      <v-container fluid class="pa-0" style="box-shadow: none">
        <p>参会身份</p>
        <v-radio-group v-model="register.identity" row>
          <!-- <v-radio label="特邀代表(组委会,大会报告人)" value="radio-1"></v-radio> -->
          <v-radio label="在职代表" value="1"></v-radio>
          <!-- <v-radio label="研究生代表" value="radio-1"></v-radio> -->
          <v-radio label="在校学生" value="2"></v-radio>
        </v-radio-group>
      </v-container>

      <div class="d-flex align-center">
        <v-text-field
          v-model="register.code"
          required
          class="required"
          label="验证码"
          :rules="rules.code"
        />
        <img
          class="ml-4"
          style="width: 100px; height: 50px"
          @click="getCode"
          :src="code"
          alt=""
        />
      </div>

      <v-btn
        :disabled="!valid"
        color="success"
        block
        class="mt-4"
        @click="registerAction"
      >
        注册
      </v-btn>
    </v-form>
    <div class="other mt-8 d-flex justify-space-between">
      <p>
        已有账号？
        <router-link :to="{ path: 'login' }">
          <span>登录</span>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      valid: false,
      redirect: '',
      register: {
        email: '',
        password: '',
        passwd: '',
        gender: '1',
        organization: '',
        position: '',
        phone: '',
        call: '',
        invoiceOrg: '',
        taxLabel: '',
        identity: '1',
        code: '',
        uuid: '',
      },
      code: '',
      rules: {
        emailRules: [
          (v) => !!v || '请输入邮箱',
          (v) => /.+@.+\..+/.test(v) || '邮箱格式不正确',
        ],
        passwd: [
          (v) => !!v || '请输入密码',
          (v) => v.length >= 6 || '密码不能小于6位',
        ],
        password: [
          (v) => !!v || '请输入确认密码',
          (v) => this.register.passwd == v || '2次密码不一致',
        ],
        realName: [(v) => !!v || '请输入姓名'],
        organization: [(v) => !!v || '请输入单位'],
        position: [(v) => !!v || '请输入职位'],
        phone: [
          (v) => !!v || '请输入手机',
          (v) =>
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
              v
            ) || '手机号不正确',
        ],
        call: [(v) => !!v || '请输入座机电话'],
        invoiceOrg: [(v) => !!v || '请输入研究方向'],
        taxLabel: [(v) => !!v || '请输入纳税人识别号'],
        code: [(v) => !!v || '请输入验证码'],
      },
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
        }
      },
      immediate: true,
    },
  },
  computed: {
    /* eslint-disable */

    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 340
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 500
        case 'xl':
          return 500
      }
    },
  },
  created() {
    this.getCode()
  },
  methods: {
    getCode() {
      this.$request({
        url: '/user/captchaImage',
        method: 'get',
      }).then((res) => {
        this.code = 'data:image/gif;base64,' + res.data.img
        this.register.uuid = res.data.uuid
      })
    },

    registerAction() {
      let data = Object.assign({}, this.register)
      data.passwd = this.$md5(this.register.passwd)
      data.password = this.$md5(this.register.password)
      if (this.$refs.form.validate()) {
        this.$request({
          url: '/user/register',
          method: 'post',
          data: data,
        })
          .then(() => {
          

            this.$router.push('/success')
          })
          .catch(() => {
            this.getCode()
          })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './login.less';
</style>
